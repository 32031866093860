import { defineComponent, toRefs, reactive, computed, watch, nextTick, defineAsyncComponent } from "vue";
import API from "@/api/siminfo/index";
import { ElRadioGroup, ElRadio } from "element-plus";
import { useMessage } from '@/hooks/web/useMessage';
import { useStore } from "vuex";
import { useDebounce } from '@/hooks/core/useDebounce';
export default defineComponent({
  name: "AddSimCard",
  components: {
    //element-plus
    ElRadioGroup,
    ElRadio,
    Eldialog: defineAsyncComponent(() => import("@/components/Eldialog/index.vue")),
    OrganizeSelect: defineAsyncComponent(() => import("@/components/OrganizeSelect/index.vue")),
    TypeSelect: defineAsyncComponent(() => import("@/components/TypeSelect/TypeSelect.vue"))
  },
  props: {
    activeOrg: {
      type: Object,
      default: {
        orgId: null,
        orgName: '',
        activeValue: null
      }
    },
    Info: {
      type: Object,
      default: null
    },
    visible: {
      type: Boolean,
      default: false
    },
    activeCardId: {
      type: Number,
      default: null
    }
  },
  emits: ["handleSuccess", "update:visible"],
  setup(props, {
    emit
  }) {
    const message = useMessage();
    const {
      state
    } = useStore();
    const refData = reactive({
      ruleForm: null,
      formData: null,
      title: '新增流量卡',
      roleList: [],
      searchValue: '',
      activeValue: '',
      rules: {
        simid: [{
          required: true,
          message: "请输入流量卡号码",
          type: 'string',
          trigger: 'blur'
        }, {
          min: 1,
          max: 15,
          message: '流量卡为11-14纯数字',
          trigger: 'blur'
        }, {
          pattern: /^[a-zA-Z0-9]*$/,
          message: '流量卡为11-14纯数字'
        }],
        iccid: [{
          required: false,
          message: "请输入iccid",
          type: 'string',
          trigger: 'blur'
        }, {
          min: 1,
          max: 20,
          message: '请输入iccid',
          trigger: 'blur'
        }, {
          pattern: /^[a-zA-Z0-9]*$/,
          message: '请输入iccid'
        }],
        holdid: [{
          required: true,
          message: "请选择组织"
        }],
        // rfid: [{
        //   required: false, message: "请填写设备RFID", type: 'string', trigger: 'blur'
        // },{
        //    min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur' 
        // },{ 
        //   pattern: /^[\u4E00-\u9FA5a-zA-Z0-9_]*$/, message: '设备RFID不能带有特殊符号' 
        // }],
        deviceType: [{
          required: true,
          message: "请选择设备型号",
          type: 'string',
          trigger: 'blur'
        }, {
          min: 1,
          max: 10,
          message: '长度在 1 到 18 个字符',
          trigger: 'blur'
        }]
      },
      confirm() {
        refData.ruleForm.validate(async valid => {
          if (valid) {
            const url = props.activeCardId ? 'updateSiminfo' : 'createSiminfo';
            console.log("props.activeCardId" + props.activeCardId);
            const {
              code,
              msg
            } = await API[url](refData.formData);
            code == 0 && (emit('handleSuccess'), emit("update:visible", false));
            message[code == 0 ? 'success' : 'warning'](code == 0 ? `${props.activeCardId ? '修改' : '添加'}成功` : msg);
          } else {
            console.log("error submit!!");
            return false;
          }
        });
      },
      async getData(id) {
        const {
          data,
          code,
          msg
        } = await API.getSiminfo(id);
        if (code == 0) {
          refData.formData = data;
          refData.searchValue = data.holdname;
        }
      },
      init() {
        refData.searchValue = '';
        refData.formData = {
          id: null,
          simid: '',
          holdid: null,
          channelId: 14,
          company: '',
          iccid: '',
          operators: '',
          status: '',
          packageName: '',
          remark: '',
          payment: null // 支付方式     
        };
      }
    });
    const defaultPassword = computed(() => {
      var _state$userInfo;
      return (_state$userInfo = state.userInfo) === null || _state$userInfo === void 0 ? void 0 : _state$userInfo.defaultPassword;
    });
    // 初始化表单
    refData.init();
    const show = computed({
      get: () => props.visible,
      set: val => {
        emit("update:visible", val);
      }
    });
    watch(() => props.visible, val => {
      if (val) {
        refData.init();
        if (props.activeCardId !== null) {
          refData.title = '修改流量卡';
          refData.getData(props.activeCardId);
        } else {
          refData.title = '添加流量卡';
          refData.formData.holdid = props.activeOrg.id;
          refData.searchValue = props.activeOrg.name;
          refData.activeValue = props.activeOrg.activeValue;
        }
        nextTick(() => {
          refData.ruleForm.clearValidate();
        });
      }
    }, {
      immediate: true
    });
    //选择组织
    const handleDoubleClick = data => {
      refData.formData.holdid = data.id;
    };
    const [onConfirm] = useDebounce(refData.confirm, 300);
    return {
      ...toRefs(refData),
      handleDoubleClick,
      show,
      onConfirm
    };
  }
});